import React from 'react';
import './ProfilePage.css';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {

  const user = {
    name: 'Kapil Kr',
    email: 'kk@hcl.com',
    phone: '+123-456-7890',
    address: 'HCL Technologies, Noida',
  };

  const navigate = useNavigate();
  const navigateRedeem = () =>{
    navigate('/profile/redeem')
  }
  return (
    <div className="container" id="container">
      <h1>Profile</h1>
      <div className="profile-data" id="profile-data">
        <img
          src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/35af6a41332353.57a1ce913e889.jpg"
          alt="Avatar"
        />
        <h2>{user.name}</h2>
        <p>Email: {user.email}</p>
        <p>Phone: {user.phone}</p>
        <p>Address: {user.address}</p>
      </div>

      <div className="sidebar-container" id="sidebar-container">
        <div className="credits-container" id="credits-container">
          <div className="coin-sidebar" id="coin-sidebar">
            <h2>Credits </h2>
            <div className="sidebar" id="sidebar">
            <Nav.Link href="#" className="redeem-link" style={{ color: 'black' }}>
            Points
            </Nav.Link>
              &nbsp;
              {/* <a href='https://www.w3schools.com/'>Redeem</a> */}
              <Nav.Link href="" className="redeem-link" style={{ color: 'black' }} onClick={navigateRedeem}>
            Redeem
            </Nav.Link>
            </div>
          </div>
        </div>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <div className="coin-sidebar-right" id="coin-sidebar-right">
          <h2>Available Points </h2>
          <div className="point-sidebar" id="point-sidebar">
            <div>
              {/* <img
                // src="https://www.flaticon.com/svg/vstatic/svg/860/860808.svg?token=exp=1664972577~hmac=3d4957446a2353a97e16496ef3f63a7c"
                alt="coin"
              /> */}
            </div>
            <div>300</div>
          </div>
        </div>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;
        </span>
        <div className="coin-sidebar-right" id="coin-sidebar-right">
          <h2>Redeemed Points </h2>
          <div className="redeem-sidebar" id="redeem-sidebar">
            <div>100</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;