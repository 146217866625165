
import React, { useState } from 'react';
import { Button, Grid, Paper, Typography, TextField, AppBar, Toolbar, InputLabel, FormControl} from '@mui/material';


const Redeem =() => {

    const [showTokenForm, setShowTokenForm] = useState(false);
    const [showVoucherForm, setShowVoucherForm] = useState(false);
    const [points, setPoints] = useState('');
    const [calculatedValue, setCalculatedValue] = useState('');
 
  const handleTokenClick = () => {
    setShowTokenForm(true);
    setShowVoucherForm(false);
    setPoints('');
    setCalculatedValue('');
  };
 
  const handleVoucherClick = () => {
    setShowTokenForm(false);
    setShowVoucherForm(true);
    setPoints('');
    setCalculatedValue('');
  };

  const handleTokenChange = (value) =>{
    // OnChange logic will add here
    setPoints(value);
    const calculatedTokenValue = parseFloat(value) * 0.2;
    setCalculatedValue(calculatedTokenValue);
  };

  const handleVoucherChange = (value) =>{
    //OnChnage logic will add here
    setPoints(value);
    const calculatedVoucherValue = parseFloat(points) * 0.2;
    setCalculatedValue(`$${calculatedVoucherValue}`);
  };


  return (
    <div style={{backgroundColor: '#f0f0f0',padding:'20px',display:'flex',justifyContent:'center',alignItems:'center'}}>
    <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} marginTop={"23px"} textAlign={'center'} >
            <Typography variant='h4' style={{marginBottom:'20px',color:'black',fontWeight:'bold'}}>
                REDEEM TOKENS
            </Typography>
        </Grid>

        
            <Grid item xs={12} spacing={20} textAlign="center">
                <Button variant="contained" color="primary" onClick={handleTokenClick} style={{marginRight: '15px'}}>
                Token Conversion
                </Button>
                <Button variant="contained" color="primary" onClick={handleVoucherClick}>
                Voucher Conversion
                </Button>
            </Grid>
        
            {showTokenForm && (
                 <Grid item xs={12} textAlign="center">
                <Paper elevation={3} style={{ padding: '20px',marginTop:'10px',width:'50%',position:'relative',display:'inline-block'}}>
                    <Typography variant="h6">Token Conversion</Typography>
                    <FormControl fullWidth margin="normal" style={{textAlign:'center'}}>
                        {<TextField label="Points to Convert" id="points" style={{width:'100%'}} margin="normal" value={points} onChange={(e) => handleTokenChange(e.target.value)}  />}
                    </FormControl>
                    <FormControl fullWidth margin='normal' style={{textAlign:'center'}}>
                        <TextField label="Total Token" id="calculated-token" style={{width: '100%'}} margin='normal' value={calculatedValue} disabled />
                    </FormControl>
                    <Button variant="contained" color="primary" >
                        Collect Token
                    </Button>                   
                </Paper>
                 </Grid>
            )}
        
            {showVoucherForm && (
                <Grid item xs={12} textAlign="center">
                <Paper elevation={3} style={{ padding: '20px',marginTop:'10px',width:'50%',position:'relative',display:'inline-block'}}>
                    <Typography variant="h6">Voucher Conversion</Typography>
                    <FormControl fullWidth margin="normal">
                        {<TextField label="Points to Convert" id="points" style={{width:'100%'}} margin="normal" value={points} onChange={(e) => handleVoucherChange(e.target.value)}  />}
                    </FormControl>
                    <FormControl fullWidth margin='normal'>
                        <TextField label="Voucher Value" id="voucher-value" style={{width: '100%'}} margin='normal' value={calculatedValue} disabled />
                    </FormControl>
                    <Button variant="contained" color="primary">
                        Collect Voucher
                    </Button>                   
                </Paper>
                </Grid>
            )}
        </Grid>
    </div>
  );
};

export default Redeem