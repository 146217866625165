import React from 'react'
import Header from './Header';

const Home = () => {
    return (
        <>
        <Header/>
        <div id="carouselExampleCaptions" className="carousel slide">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="./assets/women.jpg" className="d-block w-100 " alt="Mens_Fashion" height="500px"/>
                            <div className="carousel-caption d-none d-md-block">
                                <strong>
                                <p align="right">Check out the best clothing for women</p>
                                </strong>
                            </div>
                    </div>
                    <div className="carousel-item">
                        <img src="./assets/men.jpg" className="d-block w-100" alt="Womens_Fashion " height="500px"/>
                            <div className="carousel-caption d-none d-md-block">
                            <strong>
                                <p align="right">Check out the best clothing for Men</p>
                                </strong>
                            </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </>
    )
}

export default Home;
