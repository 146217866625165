import React from 'react'
import { NavLink } from 'react-router-dom'
import Header from './Header'

const About = () => {
  return (
    <div>
      <Header/>
      <div className="container py-4 my-5">
        <div className="row">
          <div className="col-md-6">
            <h1> About Us</h1>
            <p className="lead mb-3">
              Here our website provides you with a variety of Collections for both women and men.
              And also here you can find some electronic items and also amazing jewellery items.
              We will try and expand it to more with your greatest love and support.
              </p>
              <p className='lead'> Happy Shopping.....!
             </p>
            
            <NavLink to="/contact" className="btn btn-outline-dark px-3">
              Contact Us
            </NavLink>
          </div>
          <div className="col md-6 d-flex justify-content-center">
            <img src="/assets/about.jpg" alt="About us" height="400px" width="500px"/>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default About
