import React from "react";
import { NavLink} from 'react-router-dom';
import './Register.css';


const Register = () => {
    return (
        <div className="login-div">
            <div className="wel">
                <h4>New User Register Here..!</h4>
                <form className="form1">
                    <div className="login-container">
                        <h4><b>REGISTRATION FORM</b></h4>
                        <div data-validate="email is required">
                            <input className="input100"
                                type="text"
                                name="email"
                                placeholder="UserName"
                            />
                        </div>
                        <div data-validate="Password is required">
                            <input className="input100"
                                type="password"
                                name="password"
                                placeholder="Password"
                            />
                        </div>
                        <div data-validate="Password is required">
                            <input className="input100"
                                type="password"
                                name="confirm-password"
                                placeholder="Confirm Password"
                            />
                        </div>
                        <center><button className="btn1">Register</button></center>
                    </div>
        </form>
        </div >
    <div className="reg-div">
        <NavLink to="/login">Login</NavLink>
    </div >
    </div>
      
  );
};

export default Register;
