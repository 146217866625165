import './App.css';
import Main from './components/Main';
import Home from './components/Home';
import { Routes, Route } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';
import Product from './components/Product';
import RedeemPage from './components/RedeemPage';

function App() {
  return (
    <>
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/products' element={<Main />} />
          <Route path='/register' element={<Register/>}/>
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/productsDetails/:id' element={<Product/>}/>
          <Route path='/profile/redeem' element={<RedeemPage/>} />
        </Routes>
    </>
  ); 
}

export default App;
