import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './ProfileNavbar.css';

const ProfileNavbar = () => {

  return (
    <Navbar expand="lg" className="navbar-container" id="MainNav">
      <Nav.Link href='/' className="brand" id="bd" style={{ color: 'blue' }}>My Profile</Nav.Link>
      <Navbar.Collapse id="navbarNav">
        <Nav className="ml-auto" id='Nav1'>
          <Nav.Link href="/profile" className="nav-link" id="navLinks">
            Profile Data
          </Nav.Link>
          <Nav.Link href="/profile/redeem" className="nav-link">
            Redeem Tokens
          </Nav.Link>
          <Nav.Link href="/profile/tokens" className="nav-link">
            Tokens History
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default ProfileNavbar;

