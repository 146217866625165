import React from 'react'
import ProfileNavbar from './Navbar/ProfileNavbar'
import ProfilePage from './ProfilePage/ProfilePage'

const Profile = () => {
  return (
    <>
    <ProfileNavbar />
    <ProfilePage />
    </>
  )
}

export default Profile;