import React from 'react'
import Header from './Header'
import Data from '../data1.json';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import swal from 'sweetalert2';

const Product = () => {

    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const history = useNavigate();

    const getProductData = (id) => {
        (Data.product).map((item) => ((item.id) == id) ?
            setProduct(item)
            : null);
    }

    useEffect(() => {
        getProductData(id);
    }
    )
    const handleClick = (price) => {
        swal.fire({
            title: "Success",
            text: "Congratulations on buying this product you received $" + price,
            type: "success"
        }).then(function () {
            history('/profile');
        })
    }
    return (
        <div>
            <Header />
            <div className="container py-5">
                <div className="row py-4">
                    <div className="col-md-4 myDiv">
                        <img src={product.photo} alt={product.title}
                            height="300px" width="300px" />
                    </div>
                    <div className="col-md-6 myDiv1">
                        <h2 className="text-black-20">
                            {product.category}
                        </h2>
                        <p className="display-8">{product.name}({product.color})</p>
                        <h3 className="display-6 my-4">
                            ${product.price}
                        </h3>
                        <p className="lead">
                            Rating ({product.Rating}
                            <i className="fa fa-star"></i>)
                        </p>

                        <p className="myCoins">
                            On purchase of this product you may get ${product.price / 100}
                        </p>
                        <NavLink className="btn btn-outline-dark" onClick={() => { handleClick(product.price / 100) }}>Buy Now</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Product;