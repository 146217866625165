import React from 'react'
import ProfileNavbar from './Navbar/ProfileNavbar'
import Redeem from './Redeem'

function RedeemPage() {
  return (
    <div>
        <ProfileNavbar />
        <Redeem />
      
    </div>
  )
}

export default RedeemPage