import React from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import './login.css';


const Login = () => {
  const history = useNavigate()
  const [input, setInput] = React.useState({ email: '', password: '' });
  const userName="John";
  const userName1="Peter";
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');

  const handleChange = e => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (localStorage.getItem("UserName")) history('/')
  }, [])

  const formSubmitter = e => {
    e.preventDefault();
    setSuccessMessage('');
    
    if ((input.email !== userName || input.password !== 'Password@1') && (input.email !== userName1 || input.password !== 'Password@2') ) return setErrorMessage('Invalid email or password');

    if(input.email===userName || input.password==='Password@1')
    {
      history('/');
      localStorage.setItem("UserName", userName)
    }
    if(input.email===userName1 || input.password==='Password@2')
    {
      history('/');
      localStorage.setItem("UserName", userName1)
    }
  };
  return (
    <div className="login-div">
      <div className="wel">
        <h4>Online Shopping</h4>
        <form className="form1" onSubmit={formSubmitter}>
        <div className="login-container">
            <h4><b>LOGIN FORM</b></h4>
          {errorMessage.length > 0 && <div style={{ marginBottom: '10px', color: 'red' }}>{errorMessage}</div>}
          {successMessage.length > 0 && (<div style={{ marginBottom: '10px', color: 'green' }}>{successMessage}</div>)}
          <div data-validate="email is required">
            <input className="input100"
              type="text"
              name="email"
              placeholder="UserName"
              onChange={handleChange}
            />
          </div>
          <div data-validate="Password is required">
            <input className="input100"
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
            />
         
            <center><button className="btn1">Login</button></center>
          </div>
          </div>
        </form>
        </div>
          <div className="reg-div">
          <NavLink to="/register">New User Register Here</NavLink>
        </div>
    </div>   
  );
};

export default Login