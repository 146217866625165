import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const history = useNavigate();
    const [logout, setLogout] = React.useState(false);
    console.warn(localStorage.getItem("UserName"))
    React.useEffect(() => {
        if (!localStorage.getItem("UserName")) history("/login");
    }, [logout]);
    const logoutHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("UserName");
        setLogout(true);
    };
    return (
        <div className="header">
            <nav className="navbar navbar-expand-lg navbar-light py-1 shadow-sm">
                <div className="container">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/" >Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/products">Products</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/about">About</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                    <span className="user-span1">
    
                    <Link to="/profile">
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="40" className="bi bi-person-circle" viewBox="0 0 18 18">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                        </svg>
                        </Link>
                    </span>
                    <span className="user-span">{localStorage.getItem("UserName")}</span>
                </div>
                <button className="btn btn-outline-dark lg-btn" onClick={logoutHandler}>
                    Logout
                </button>
            </nav>

        </div>
    )
}
export default Header;
