import Header from './Header';
import Data from '../data1.json';
import { NavLink } from 'react-router-dom';

function Main() {

  return (
    <div>
      <Header />
      <div className='product-container'>
        {
          (Data.product).map((item) => <div key={item.id} className='product-item'>
            <img src={item.photo} width={200} height={200} alt="" />
            <center><div> {item.name} ({item.color})</div>
              <div><b>${item.price}</b></div>
              <div>{item.category}</div>
              <div>
                <NavLink to={`/productsDetails/${item.id}`} className="btn btn-outline-dark">View Details</NavLink>
              </div>
            </center>
          </div>)
        }
      </div>
    </div>
  );
}

export default Main;