// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MainNav {
  background-color: #cae2ea;
  padding: 10px 20px;
}

#bd {
  color: #fff;
  font-size: 24px;
  margin-top: 10px; 
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
}

#Nav1{
  text-align: right;
  margin-bottom: 20px ;
  margin-left: 60px;
  padding-left: 60px;
}
#navLinks {
  color: #000000;
  margin-right: 15px;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: bold;
}

#navLinks:hover {
  color: #ecf0f1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/ProfileNavbar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["#MainNav {\n  background-color: #cae2ea;\n  padding: 10px 20px;\n}\n\n#bd {\n  color: #fff;\n  font-size: 24px;\n  margin-top: 10px; \n  font-weight: bold;\n  margin-bottom: 10px;\n  margin-left: 10px;\n}\n\n#Nav1{\n  text-align: right;\n  margin-bottom: 20px ;\n  margin-left: 60px;\n  padding-left: 60px;\n}\n#navLinks {\n  color: #000000;\n  margin-right: 15px;\n  font-size: 18px;\n  text-decoration: none;\n  transition: color 0.3s ease;\n  font-weight: bold;\n}\n\n#navLinks:hover {\n  color: #ecf0f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
