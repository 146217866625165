import React from 'react'
import Header from './Header'

const Contact = () => {
  return (
    <div>
      <Header/>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 text-center py-4 my-4">
            <h1> Have Any Questions? </h1>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 d-flex justify-content-center">
            <img src="/assets/mail.jfif" alt="Contact us" height="300px" width="300px" />
          </div>
          <div className="col-md-6">
            <form>
              <div class="mb-3">
                <input type="text" class="form-control" id="exampleForm" placeholder="Name" required="true"/>
              </div>
              <div class="mb-3">
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" reuired="true"/>
              </div>
              <div class="mb-3">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
              <button type="submit" class="btn btn-outline-dark">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
